<template>
  <div
    style="margin-top:-50px;"
  >
    <div
      v-if="showBackToHomeLink"
    >
      <b-link
        :to="{ name: 'home' }"
        class="font-weight-bold d-block text-nowrap"
        :style="getHrefStyle()"
      >
        <feather-icon
          icon="ArrowLeftCircleIcon"
          size="16"
          class="mr-0 mr-sm-50"
          :style="getHrefStyle()"
        />
        <span
          :style="getHrefStyle()"
        >
          Zurück zum Kundenportal
        </span>
      </b-link>
      <br>
    </div>
    <validation-observer ref="validateUserDetailsForm">
      <b-form
        @submit.prevent="onSubmitData"
      >
        <b-card>
          <br>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <div class="d-flex">
                <feather-icon
                  icon="UserIcon"
                  size="19"
                />
                <h4 class="mb-0 ml-50">
                  Persönliche Daten
                </h4>
              </div>
              <br>
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      md="12"
                    >
                      <b-form-group
                        label="Email"
                        label-for="email"
                      >
                        <b-form-input
                          id="email"
                          v-model="userData.email"
                          type="email"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                      md="12"
                    >
                      <b-form-group
                        label="Kontakt email"
                        label-for="contactEmail"
                      >
                        <b-form-input
                          id="contactEmail"
                          v-model="userData.contactEmail"
                          type="email"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-tooltip
                        target="customerType-tooltip"
                        title="Bei Firmenfahrzeugen kann nur der Besitzer laut Zulassungsschein durch einen berechtigten Vertreter den Bonus beantragen. Der Besitzer ist in der Regel das Unternehmen oder ein Leasingunternehmen. Bitte die Eingabe prüfen."
                      />
                      <b-form-group>
                        <label
                          for="customerType"
                          class="d-block"
                        >
                          Kundentyp*
                          <feather-icon
                            id="customerType-tooltip"
                            icon="InfoIcon"
                            size="15"
                            style="color:red;"
                          />
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Kundentyp"
                          rules="required"
                        >
                          <b-form-radio-group
                            v-model="userData.customerType"
                            :button-variant="`outline-secondary`"
                            buttons
                            name="radio-customerType"
                            size="sm"
                            style="width:100%"
                          >
                            <b-form-radio value="1">
                              <feather-icon
                                icon="UserIcon"
                                size="15"
                              />
                              Privatkunde
                            </b-form-radio>
                            <b-form-radio value="2">
                              <feather-icon
                                icon="BriefcaseIcon"
                                size="15"
                              />
                              Unternehmen
                            </b-form-radio>
                          </b-form-radio-group>
                          <small class="text-warning"><br>{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="userData.customerType==2"
                      cols="12"
                      md="4"
                    >
                      <b-form-group
                        label="Firmenbezeichnung*"
                        label-for="companyTitle"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Firmenbezeichnung"
                          rules="required"
                        >
                          <b-form-input
                            id="companyTitle"
                            v-model="userData.companyTitle"
                            trim
                            placeholder="Eingabe Firmenbezeichnung"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-warning">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!--
                    <b-col
                      v-if="userData.customerType==2 && companyVatNo.display==true"
                      cols="12"
                      md="4"
                    >
                      <b-form-group
                        :label="companyVatNo.label"
                        label-for="companyVatNo"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="UID"
                          :rules="companyVatNo.required"
                        >
                          <b-form-input
                            id="companyVatNo"
                            v-model="userData.companyVatNo"
                            trim
                            placeholder="Eingabe UID"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-warning">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    -->
                    <b-col
                      v-if="userData.customerType==2"
                      cols="12"
                      md="4"
                    >
                      <b-form-group
                        label="UID (Steuernummer falls keine UID)*"
                        label-for="companyVatNo"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="UID"
                          rules="required|uid"
                        >
                          <b-form-input
                            id="companyVatNo"
                            v-model="userData.companyVatNo"
                            trim
                            placeholder="Eingabe UID"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-warning">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row
                    v-if="userData.customerType == 2"
                  >
                    <b-col
                      cols="12"
                      md="12"
                    >
                      Ansprechpartner:
                    </b-col>
                  </b-row>
                  <b-row
                    v-if="userData.customerType != 2"
                  >
                    <b-col
                      cols="12"
                      md="12"
                    >
                      &nbsp;
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      id="gender_col_id"
                      cols="12"
                      md="4"
                    >
                      <b-form-group
                        label="Anrede*"
                        label-for="gender"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Anrede"
                          rules="required"
                        >
                          <b-form-radio-group
                            id="gender"
                            v-model="userData.gender"
                            button-variant="outline-secondary"
                            :options="gender_options"
                            buttons
                            block
                            name="radio-gender"
                            size="sm"
                            style="width:100%"
                          />
                          <small class="text-warning"><br>{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group
                        label="Titel"
                        label-for="titleSuffix"
                      >
                        <b-form-input
                          id="titleSuffix"
                          v-model="userData.titleSuffix"
                          trim
                          placeholder="z.B. Mag., Dr., DI, Ing., MBA, etc."
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group
                        label="Titel nachgestellt"
                        label-for="titleSuffixReal"
                      >
                        <b-form-input
                          id="titleSuffixReal"
                          v-model="userData.titleSuffixReal"
                          trim
                          placeholder="z.B. BA, MA, PhD, etc."
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      id="firstname_col_id"
                      cols="12"
                      md="4"
                    >
                      <b-form-group
                        label="Vorname*"
                        label-for="firstname"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Vorname"
                          rules="required"
                        >
                          <b-form-input
                            id="firstname"
                            v-model="userData.firstName"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-warning">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group
                        label="Nachname*"
                        label-for="lastName"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Nachname"
                          rules="required"
                        >
                          <b-form-input
                            id="lastName"
                            v-model="userData.lastName"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-warning">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- v-if="userData.customerType!=2" -->

                    <b-col
                      v-if="userData.customerType !=2 && birthday.display==true"
                      cols="12"
                      md="4"
                    >
                      <!--
                      <b-form-group
                        :label="birthday.label"
                        label-for="birthday"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Geburtsdatum"
                          :rules="birthday.required"
                        >
                          <b-form-datepicker
                            id="birthday"
                            v-model="userData.birthday"
                            :state="errors.length > 0 ? false:null"
                            locale="de"
                            v-bind="labels.de || {}"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            :show-decade-nav="true"
                          />
                          <small class="text-warning">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      -->
                      <!--
                      <b-form-group
                        :label="birthday.label"
                        label-for="birthday"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Geburtsdatum"
                          rules="required|length:10"
                        >
                          <cleave
                            id="birthday"
                            v-model="userData.birthday"
                            class="form-control"
                            :raw="false"
                            :options="options.date"
                            placeholder="tt.mm.jjjj"
                            @change="applyErrorStye('birthday', errors.length)"
                          />
                          {{ errors.length }}
                          <small class="text-warning">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      -->

                      <b-form-group
                        :label="birthday.label"
                        label-for="birthday"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Geburtsdatum"
                          :rules="birthday.required"
                        >
                          <b-form-input
                            id="birthday"
                            v-model="userData.birthday"
                            class="form-control"
                            type="date"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-warning">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-tooltip
                        target="phoneNr-tooltip"
                        title="Wenn Sie uns Ihre Telefonnummer zur Verfügung stellen, können wir Sie bei Rückfragen einfacher und schneller erreichen."
                      />
                      <b-form-group>
                        <label
                          for="phoneNr"
                          class="d-block"
                        >
                          Telefonnummer
                          <feather-icon
                            id="phoneNr-tooltip"
                            icon="InfoIcon"
                            size="15"
                          />
                        </label>
                        <b-form-input
                          id="phoneNr"
                          v-model="userData.phoneNr"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <br>
          <br>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <div class="d-flex">
                <feather-icon
                  icon="HomeIcon"
                  size="19"
                />
                <h4 class="mb-0 ml-50">
                  Adresse
                </h4>
              </div>
              <br>
              <b-alert
                variant="secondary"
                :show="true"
              >
                <div class="alert-body">
                  <span style="font-size:13px;">
                    Die Adressdaten müssen mit den Adressdaten des/der Zulassungsbesitzers/in übereinstimmen.<br>Tun Sie das nicht geben Sie beim jeweiligen Fahrzeug bitte im Infofeld eine Begründung an.
                  </span>
                </div>
              </b-alert>
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-tooltip
                    target="street-tooltip"
                    title="Adresse muss mit der Adresse im Zulassungsschein übereinstimmen."
                  />
                  <b-form-group>
                    <label
                      for="street"
                      class="d-block"
                    >
                      Strasse*
                      <feather-icon
                        id="street-tooltip"
                        icon="InfoIcon"
                        size="15"
                      />
                    </label>
                    <validation-provider
                      #default="{ errors }"
                      name="Strasse"
                      rules="required"
                    >
                      <b-form-input
                        id="street"
                        v-model="addressData.street"
                        trim
                        placeholder="Eingabe Strasse"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-warning">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label="Postleitzahl*"
                    label-for="zip"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Postleitzahl"
                      rules="required"
                    >
                      <b-form-input
                        id="zip"
                        v-model="addressData.zip"
                        trim
                        placeholder="Eingabe Plz"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-warning">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label="Ort*"
                    label-for="city"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Ort"
                      rules="required"
                    >
                      <b-form-input
                        id="city"
                        v-model="addressData.city"
                        trim
                        placeholder="Eingabe Ort"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-warning">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Adress-Zusatz"
                    label-for="addressExtra"
                  >
                    <b-form-input
                      id="addressExtra"
                      v-model="addressData.addressExtra"
                      trim
                      placeholder="Eingabe Adress-Zusatz"
                    />
                  </b-form-group>
                </b-col>

              </b-row>
            </b-col>
          </b-row>
          <br>
          <br>
          <b-row
            v-if="partnerData.paysOutBonus"
          >
            <b-col
              cols="12"
              md="12"
            >
              <div class="d-flex">
                <feather-icon
                  icon="CreditCardIcon"
                  size="19"
                />
                <h4 class="mb-0 ml-50">
                  Kontoinformationen
                </h4>
              </div>
              <br>
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-tooltip
                    target="fullName-tooltip"
                    title="Nur wenn Sie uns diese Daten zur Verfügung stellen können wir Ihnen einen Geldbetrag überweisen. Besteht der gewährte Bonus aus einem Geldbetrag, müssen Sie diese Angaben ausfüllen, können dies aber auch zu einem späteren Zeitpunkt tun."
                  />
                  <b-form-group>
                    <label
                      for="fullName"
                      class="d-block"
                    >
                      Kontoinhaber
                      <feather-icon
                        id="fullName-tooltip"
                        icon="InfoIcon"
                        size="15"
                      />
                    </label>
                    <validation-provider
                      #default="{ errors }"
                      name="Kontoinhaber"
                      rules="required|alpha_num_spaces|max:70"
                    >
                      <b-form-input
                        id="fullName"
                        v-model="sepaData.fullName"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-warning">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="8"
                >
                  <b-form-group
                    label="IBAN"
                    label-for="account"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="IBAN"
                      rules="required|iban_eu|iban"
                    >
                      <the-mask
                        id="account"
                        v-model="sepaData.account"
                        class="form-control"
                        mask="AA## #### #### #### #### #### ##"
                        :class="errors.length > 0 ? 'is-invalid' : ''"
                      />
                      <small class="text-warning">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label="BIC"
                    label-for="bic"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="BIC"
                      rules="alpha_num|uppercase"
                    >
                      <b-form-input
                        id="bic"
                        v-model="sepaData.bic"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-warning">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <br><br>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Zustimmung"
                  :rules="{ required: { allowFalse: false } }"
                >
                  <b-form-checkbox
                    v-model="userData.userAgreesWithTermsAndConditions"
                    :state="errors.length > 0 ? false:null"
                    :class="`custom-control-${partnerData.colorScheme}`"
                  >
                    <span style="color:#6e6b7b">
                      Die allgemeinen&nbsp;
                      <a
                        v-if="parseInt(userData.customerType, 10) == 2"
                        :href="partnerData.termsConditionsLinkCompany"
                        target="_blank"
                        :style="getHrefStyle()"
                      >
                        Geschäftsbedingungen
                      </a>
                      <a
                        v-else
                        :href="partnerData.termsConditionsLink"
                        target="_blank"
                        :style="getHrefStyle()"
                      >
                        Geschäftsbedingungen
                      </a>
                      &nbsp;sowie die&nbsp;
                      <a :href="partnerData.dataProtectionLink" target="_blank" :style="getHrefStyle()">Datenschutzbestimmungen</a> habe ich gelesen und akzeptiere diese. Weiter bestätige ich die Richtigkeit aller gemachten Angaben.
                    </span>
                  </b-form-checkbox>
                  <small class="text-warning">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            v-if="gdprExternal.display==true"
          >
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Einwilligung"
                  :rules="gdprExternal.required"
                >
                  <b-form-checkbox
                    v-model="userData.gdprExternal"
                    :state="errors.length > 0 ? false:null"
                    :class="`custom-control-${partnerData.colorScheme}`"
                  >
                    <span
                      v-html="gdprExternal.label"
                      style="color:#6e6b7b"
                    />
                  </b-form-checkbox>
                  <small class="text-warning">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <br><br>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-button
                :variant="partnerData.colorScheme"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                type="submit"
                @click.prevent="validateForm"
              >
                Registrierung abschliessen
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BCard, BLink, BFormCheckbox, BFormRadioGroup, BFormRadio, BTooltip, BAlert, BFormDatepicker,
} from 'bootstrap-vue'
import {TheMask} from 'vue-the-mask'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, localize, extend, defineRule } from 'vee-validate'
import { required } from '@validations'
import Cleave from 'vue-cleave-component'
import { avatarText } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { numberFormat, numberFormatDeep } from '@core/utils/localSettings'
import router from '@/router'
import store from '@/store'
import userStoreModule from '../userStoreModule'
import errorMsg from '../../../../globalErrorMessages'
import { max, alpha_spaces, alpha_num } from 'vee-validate/dist/rules'

extend('max', max)
extend('alpha_spaces', alpha_spaces)
extend('alpha_num', alpha_num)
extend('uppercase', value => {
  const regex = /^[A-Z0-9]+$/
  return regex.test(value) || 'Es sind nur Großbuchstaben zulässig'
})

extend('alpha_num_spaces', {
  validate: value => {
    const regex = /^[a-zA-Z0-9\s\.\,\-\(\)\/\&\+\!\?']{1,70}$/;
    return regex.test(value)
  },
  message: 'Kontoinhaber darf nur alphanumerische Zeichen enthalten',
})

extend('iban_eu', {
  validate: value => {
    const validEUCountries = [
      'AT', 'BE', 'BG', 'CY', 'CZ', 'DE', 'DK', 'EE', 'ES', 'FI', 'FR',
      'GR', 'HR', 'HU', 'IE', 'IT', 'LT', 'LU', 'LV', 'MT', 'NL', 'PL',
      'PT', 'RO', 'SE', 'SI', 'SK', 'GB',
    ]

    if (value.length < 2) return false
    const countryCode = value.slice(0, 2).toUpperCase()

    const ibanRegex = /^[A-Z]{2}[0-9A-Z]{13,34}$/
    return validEUCountries.includes(countryCode) && ibanRegex.test(value)
  },
  message: 'IBAN nicht gültig',
})

localize('de')
export default {
  components: {
    BLink,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BFormRadioGroup,
    BFormRadio,
    BTooltip,
    Cleave,
    BAlert,
    BFormDatepicker,
    TheMask,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      gender_options: [
        // . { value: null, text: 'Bitte auswählen' },
        { value: '1', text: 'Herr' },
        { value: '2', text: 'Frau' },
      ],
      customerTypes: [
        { value: '1', text: 'Privatkunde' },
        { value: '2', text: 'Unternehmen' },
      ],
      options: {
        number: numberFormat,
        numberDeep: numberFormatDeep,
        iban: {
          blocks: [4, 4, 4, 4, 4],
          uppercase: true,
        },
        date: {
          date: true,
          delimiter: '.',
          datePattern: ['d', 'm', 'Y'],
        },
      },
      labels: {
        de: {
          labelPrevDecade: 'Vorheriges Jahrzehnt',
          labelPrevYear: 'Vorheriges Jahr',
          labelPrevMonth: 'Vorheriger Monat',
          labelCurrentMonth: 'Aktueller Monat',
          labelNextMonth: 'Nächster Monat',
          labelNextYear: 'Nächstes Jahr',
          labelNextDecade: 'Nächstes Jahrzehnt',
          labelToday: 'Heute',
          labelSelected: 'Ausgewähltes Datum',
          labelNoDateSelected: 'Kein Datum gewählt',
          labelCalendar: 'Kalender',
          labelNav: 'Kalendernavigation',
          labelHelp: 'Mit den Pfeiltasten durch den Kalender navigieren',
        },
      },
      required,
    }
  },
  methods: {
    validateForm() {
      this.$refs.validateUserDetailsForm.validate().then(ok => {
        if (ok) {
          this.onSubmitData()
        }
      })
    },
  },
  setup() {
    const toast = useToast()

    const userData = ref({})
    const addressData = ref({})
    const sepaData = ref({})
    const showBackToHomeLink = ref(true)

    const partner = ref(window.$cookies.get('partner'))
    const partnerData = ref(window.$cookies.get('partnerData'))
    const getHrefStyle = () => `color:var(--${partnerData.value.colorScheme})`
    document.title = partnerData.value.htmlPageTitle
    document.querySelector("link[rel*='icon']").href = `favicon_${partnerData.value.key}.ico`

    if (router.currentRoute.params.forceRedirect) {
      showBackToHomeLink.value = false
    }

    const APP_USER_STORE_MODULE_NAME = 'app-user'

    const extraFieldsDefinition = JSON.parse(partnerData.value.extraFieldsDefinition)

    // extra fields
    const birthday = ref({ display: false, label: '', required: 'false' })
    const companyVatNo = ref({ display: false, label: '', required: 'false' })
    const gdprExternal = ref({ display: false, label: '', required: 'false' })

    if (extraFieldsDefinition !== null) {
      if (typeof extraFieldsDefinition.user !== 'undefined') {
        if (typeof extraFieldsDefinition.user.birthday !== 'undefined') {
          birthday.value = extraFieldsDefinition.user.birthday
        }
        if (typeof extraFieldsDefinition.user.companyVatNo !== 'undefined') {
          companyVatNo.value = extraFieldsDefinition.user.companyVatNo
        }
        if (typeof extraFieldsDefinition.user.gdprExternal !== 'undefined') {
          gdprExternal.value = extraFieldsDefinition.user.gdprExternal
        }
      }
    }

    // Register module
    if (!store.hasModule(APP_USER_STORE_MODULE_NAME)) store.registerModule(APP_USER_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_USER_STORE_MODULE_NAME)) store.unregisterModule(APP_USER_STORE_MODULE_NAME)
    })

    const applyErrorStye = (id, error) => {
      console.log(id)
      console.log(error)
    }

    const onSubmitData = () => {
      store.dispatch('app-user/save', {
        id: router.currentRoute.params.userId,
        addressData,
        userData,
        sepaData,
      })
        .then(response => {
          if (response.status === 200) {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Erfolg',
                text: 'Daten erfolgreich aktualisiert.',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            showBackToHomeLink.value = true
            router.push({ name: 'home' })
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Fehler',
                text: 'Fehler beim Aktualisieren.',
                icon: 'AlertTriangleIcon',
                variant: 'warning',
              },
            })
          }
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Fehler',
              text: errorMsg.errorMsg.serverOffline,
              icon: 'AlertTriangleIcon',
              variant: 'warning',
            },
          })
        })
    }

    console.log('getuserdata')
    store.dispatch('app-user/get', { id: router.currentRoute.params.userId })
      .then(response => {
        if (response.status === 200) {
          userData.value = response.data.user
          addressData.value = response.data.address
          sepaData.value = response.data.sepa
        }
      })
      .catch(error => {
        console.log(error.response.status)
        if (error.response.status === 401) {
          window.$cookies.remove('userData')
          window.$cookies.remove('accessToken')
          window.$cookies.remove('refreshToken')
          router.go('/login')
        } else {
          router.push({ name: 'home' })
        }
      })

    return {
      userData,
      addressData,
      sepaData,
      showBackToHomeLink,

      onSubmitData,
      avatarText,

      partner,
      partnerData,
      getHrefStyle,

      birthday,
      companyVatNo,
      gdprExternal,
      applyErrorStye,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
